<template>
    <div>
        <div class="mb-4">
            <info-collapse-area>
                <template v-slot:title>
                    <div class="w-100 d-flex flex-column flex-md-row justify-content-md-between">
                        <div class="mt-1 mt-md-0 mb-1 mb-md-0 fs-16">
                            {{ getObjectValue(application, 'name') }}
                        </div>
                        <div class="mb-1 mb-md-0 mr-0 mr-md-4">
                            {{ application.status_text }}
                        </div>
                    </div>
                </template>
            </info-collapse-area>
        </div>
        <div class="d-flex">
            <div class="mr-3" style="flex-basis: 250px; flex-shrink: 0">
                <div v-for="(menu,menuKey) in menus"
                     :key="menuKey">
                    <b-button variant="block justify-content-start h-auto font-weight-medium line-height-normal py-2 text-left mt-0 mb-1"
                              :class="[menuSelected === menu.slug ? 'btn-primary' : 'secondary-border-lighter btn-outline-secondary']"
                              @click="menuChange(menu.slug)"
                    >
                        {{ menu.title }}
                    </b-button>
                </div>
            </div>
            <div class="flex-grow-1">
                <div class="border rounded-sm p-4" v-show="menuSelected=='personal_information'">
                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('nationality')">
                                <div class="label-as-input">{{ getObjectValue(application, 'nationality_code') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('national_id')" v-if="application.nationality_code=='TR'">
                                <div class="label-as-input">{{ getObjectValue(application, 'national_id') }}</div>
                            </b-form-group>
                            <b-form-group :label="$t('passport_number')" v-else>
                                <div class="label-as-input">{{ getObjectValue(application, 'passport_number') }}</div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('name')">
                                <div class="label-as-input">{{ getObjectValue(application, 'name') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('surname')">
                                <div class="label-as-input">{{ getObjectValue(application, 'surname') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('father_name')">
                                <div class="label-as-input">{{ getObjectValue(application, 'father_name') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('gender')">
                                <div class="label-as-input">{{ getObjectValue(application, 'gender') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('birthdate')">
                                <div class="label-as-input">{{ toLocaleDate(application.birthdate) }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('birthplace')">
                                <div class="label-as-input">{{ getObjectValue(application, 'birthplace') }}</div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected=='contact_information'">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group :label="$t('address')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'address') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('email')">
                                <div class="label-as-input">{{ getObjectValue(application, 'email') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('telephone')">
                                <div class="label-as-input">{{ getObjectValue(application, 'phone') }}</div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected=='education_information'">
                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('country')">
                                <div class="label-as-input">
                                    {{ $t('turkey') }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('university')">
                                <div class="label-as-input">
                                <span v-if="application.other_university">
                                    {{ application.other_university }}
                                </span>
                                    <span>
                                    {{ application.university.name }}
                                </span>
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('faculty')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'faculty') }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('program')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'program') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('level')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'level_name') }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('entry_type')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'entry_type') }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('entry_score')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'entry_score') }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('gno')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'gno') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('finished_class')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'finished_class') }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('number_of_semesters')">
                                <div class="label-as-input">
                                    {{ application.semester_count ? $t('semester_x', {semester: application.semester_count}) : '-' }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('registration_date')">
                                <div class="label-as-input">
                                    {{ toLocaleDate(getObjectValue(application, 'registration_date')) }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('proficiency_status')">
                                <div class="label-as-input">
                                    <div v-if="isNotNull(application.proficiency_status)">
                                        {{ application.proficiency_status ? $t('studied') : $t('not_studied') }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('is_before_undergraduate_transfer')">
                                <div class="label-as-input">
                                    {{ application.before_undergraduate_transfer ? $t('yes') : $t('no') }}
                                </div>
                            </b-form-group>
                            <b-form-group :label="$t('is_before_undergraduate_transfer_type')" v-if="application.before_undergraduate_transfer">
                                <div class="label-as-input">
                                    {{ application.is_before_undergraduate_transfer_type }}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected=='preferences'">
                    <h6 class="mb-2">{{ $t('preferences') }}</h6>
                    <b-table
                        :empty-text="$t('there_are_no_records')"
                        bordered
                        striped
                        responsive
                        show-empty
                        :fields="preferencesFields"
                        :items="application.preferences"
                        sort-by="code"
                        class="mb-0 border rounded"
                    >
                        <template #cell(faculty_program)="row">
                            {{ row.item.faculty.name }} <br>
                            {{ row.item.program.name }}
                        </template>
                        <template #cell(type)="row">
                            {{ row.item.transfer_type_text }}
                        </template>
                        <template #cell(status)="row">
                            {{ row.item.status_text }}
                        </template>
                    </b-table>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected=='documents'">
                    <h6 class="mb-2">{{ $t('documents') }}</h6>
                    <b-table
                        :empty-text="$t('there_are_no_records')"
                        bordered
                        striped
                        responsive
                        show-empty
                        :fields="documentFields"
                        :items="objectToArrayObject(application.documents)"
                        sort-by="code"
                        class="mb-0 border rounded"
                    >
                        <template #cell(rank)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(faculty_program)="row">
                            {{ row.item.faculty_name }} <br>
                            {{ row.item.program_name }}
                        </template>
                        <template #cell(status)="row">
                            <span v-if="row.item.status=='uploaded'">
                                {{ $t('status_approval_awaited') }}
                            </span>
                            <span v-else>
                                {{ $t('status_' + row.item.status) }}
                            </span>
                        </template>
                        <template #cell(processes)="row">
                            <div class="d-flex justify-content-center">
                                indir
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Component
    import InfoCollapseArea from '@/components/elements/InfoCollapseArea'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            InfoCollapseArea,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number,
                default: null
            },
            formData: {
                type: Object,
                default: {}
            }
        },
        created() {
            this.application = this.formData
        },
        data() {
            return {
                menus: [
                    {
                        title: this.$i18n.t('personal_information'),
                        slug: 'personal_information'
                    },
                    {
                        title: this.$i18n.t('contact_information'),
                        slug: 'contact_information'
                    },
                    {
                        title: this.$i18n.t('education_info'),
                        slug: 'education_information'
                    },
                    {
                        title: this.$i18n.t('preferences'),
                        slug: 'preferences'
                    },
                    {
                        title: this.$i18n.t('documents'),
                        slug: 'documents'
                    }
                ],
                menuSelected: 'personal_information',

                application: {},
                preferencesFields: [
                    {
                        key: 'rank',
                        label: this.toUpperCase('rank'),
                        sortable: true,
                        tdClass: 'text-center align-middle',
                        thClass: 'text-center'
                    },
                    {
                        key: 'faculty_program',
                        label: this.toUpperCase('faculty') + ' / ' + this.toUpperCase('program'),
                        sortable: true,
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'type',
                        label: this.toUpperCase('type'),
                        sortable: true,
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'status',
                        label: this.toUpperCase('status'),
                        sortable: true,
                        tdClass: 'align-middle'
                    }
                ],
                documentFields: [
                    {
                        key: 'rank',
                        label: this.toUpperCase('rank'),
                        sortable: true,
                        tdClass: 'text-center align-middle',
                        thClass: 'text-center'
                    },
                    {
                        key: 'name',
                        label: this.toUpperCase('name'),
                        sortable: true,
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'status',
                        label: this.toUpperCase('status'),
                        sortable: true,
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'processes',
                        label: this.toUpperCase('processes'),
                        sortable: true,
                        thClass: 'text-center',
                        tdClass: 'text-center align-middle'
                    }
                ]
            }
        },
        methods: {
            menuChange(key) {
                this.menuSelected = key;
            }
        }
    }
</script>
